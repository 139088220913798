<template>
    <div id="settingEditPage">
      <div class="container">
        <div class="input-list">
          <div class="input-item">
            <i class="icon icon-email"></i>
            <input
              class="input"
              v-model="info.value"
              type="email"
              :placeholder="info.placeholder"
              :disabled="info.disable"
            >
          </div>
        </div>
        <div @click="onSubmit" class="submit-btn">
          <span>{{info.btnName}}</span>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'SettingEdit',
  components: {},
  data () {
    return {
      info: {
        value: '',
        disable: false
      }
    };
  },
  created () {
  },
  mounted () {
    const { query, meta } = this.$route;
    let type = query.type * 1;
    if (!type) {
      return this.$router.replace({
        name: 'SettingIndex'
      });
    }
    const info = {
      1: {
        value: '',
        title: 'メールアドレスの変更',
        placeholder: 'メールアドレスを入力',
        disable: false,
        btnName: '送信'
      },
      2: {
        value: this.$utils.storage.get('email'),
        title: 'パスワードを変更',
        placeholder: '',
        disable: true,
        btnName: '送信'
      }
    };
    this.type = type;
    this.info = info[type];
    meta.title = this.info.title;
  },
  watch: {},
  methods: {
    onSubmit () {
      const { info: { value }, type, $utils: { verifyEmpty, verifyEmail, toast }, $dialog, $router } = this;
      switch (type) {
        case 1:
          if (verifyEmpty(value, 'メールアドレスを記入してください') && verifyEmail(value)) {
            this.$http.post('set/modifyEmail', {
              email: value
            }).then((res) => {
              toast({
                message: '送信済み。再登録してください',
                onClose () {
                  $router.push({
                    name: 'Login',
                    params: {
                      type: 1
                    }
                  });
                }
              });
              this.$utils.storage.remove('loginToken');
            });
          }
          break;
        case 2:
          this.$http.get('security/forgetPassEmail', {
            params: {
              email: value,
              url: 'user/pwChange/forgot'
            }
          }).then((res) => {
            $dialog.alert({
              title: 'パスワードを再設定',
              message: `パスワード再設定用リンクを${value}に送信しましす`,
              theme: 'round-button',
              confirmButtonText: 'はい',
              closeOnClickOverlay: true
            }).then(() => {
              // $router.push({
              //   name: 'PwChange',
              //   params: {
              //     type: 'modify'
              //   }
              // });
              this.$utils.successToast('送信済み');

            }).catch();
          });
          break;
      }
    }
  }
};
</script>

<style scoped lang="less">
  #settingEditPage {
    padding: 56px 28px;

    .container {
      .input-list {
        .input-item {
          .icon-email {
            background-image: url("~@/assets/images/icon_news_06.png");
          }
        }
      }

      .submit-btn {
        margin-top: 236px;
      }
    }
  }
</style>